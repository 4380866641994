export type DataSpecification = Array<{
  field: string
  isOnProductDetails: boolean
  name: string
}>

const specificationNameFormatter = (name: string) => {
  return name?.replace(' ', '-')?.toLocaleLowerCase()
}

const checkSpecification = (
  specName: string,
  specificationData: DataSpecification
) => {
  return specificationData?.find((specification) => {
    return specificationNameFormatter(specification?.name) === specName
  })
}

export const handleKeyTransform = (
  key: string,
  specificationData: DataSpecification
) => {
  switch (key) {
    case 'altura':
      return checkSpecification('altura-aprox', specificationData)
        ? 'altura-aprox'
        : key

    case 'largura':
      return checkSpecification('largura-aprox', specificationData)
        ? 'largura-aprox'
        : key

    case 'comprimento':
      return checkSpecification('comprimento-aprox', specificationData)
        ? 'comprimento-aprox'
        : key

    default:
      return key
  }
}
