import type { ItemListElements } from 'gatsby-plugin-next-seo'

export function generateBreadcrumb(
  path: string,
  origin: string
): ItemListElements[] {
  const segments = path.split('/')
  const itemListElements: ItemListElements[] = []

  let currentItem = ''

  for (let i = 0; i < segments.length; i++) {
    if (segments[i] !== '') {
      currentItem += `/${segments[i]}`
    }

    itemListElements.push({
      position: i + 1,
      name:
        segments[i] === ''
          ? 'Home'
          : segments[i].charAt(0).toUpperCase() + segments[i].slice(1),
      item: origin + currentItem,
    })
  }

  return itemListElements
}
