import type { CmsBlock } from '@generated/graphql'
import type { FC } from 'react'
import React from 'react'
import RenderCMS from 'src/components/RenderCMS'

interface HomePage {
  cmsData: {
    sections: Array<{ name: string; data: CmsBlock['data'] }>
    config: {
      slugAndFilterConfig: {
        slug: string | null
        background?: string | null
      } | null
    } | null
  }
}

const Above: FC<HomePage> = (props) => {
  const { cmsData } = props
  const background =
    cmsData?.config?.slugAndFilterConfig?.background ?? '#FFFFFF'

  const afterGallerySectionIndex = cmsData?.sections.findIndex(
    (sectionObject) => sectionObject.name === 'AfterGallery'
  )

  const beforeGallerySectionsComponents =
    afterGallerySectionIndex !== -1
      ? cmsData?.sections.slice(0, afterGallerySectionIndex)
      : cmsData?.sections

  return (
    <section
      className="page__section cms-departament-components"
      style={{ background }}
    >
      <RenderCMS sections={beforeGallerySectionsComponents} fold="above" />
    </section>
  )
}

export default Above
