import type { SearchState } from '@faststore/sdk'
import { parseSearchState, SearchProvider } from '@faststore/sdk'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'
import { applySearchState } from 'src/sdk/search/state'
import Seo from 'src/views/LP/Seo'
import Above from 'src/views/LP/Above'
import Below from 'src/views/LP/Below'
import 'src/styles/pages/product-listing-page.scss'
import 'src/styles/pages/lp.scss'
import type {
  CmsBlock,
  LandingPageQueryQuery,
  LandingPageQueryQueryVariables,
  CmsInstitutionalPage,
} from '@generated/graphql'
import type { Facet, SearchSort } from '@faststore/sdk/dist/types'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageViewEvent'
import { useSession } from 'src/sdk/session'
import { OptionsMap } from 'src/components/search/Sort/SorterInternalComponent'
import { FilterContextProvider } from 'src/contexts/FilterContext/filter-context'
import { useCampaignContext } from 'src/contexts/campaign-context'
import type { DataSpecification } from 'src/utils/lpFacetsHelper'
import { handleKeyTransform } from 'src/utils/lpFacetsHelper'

import productPerPage from '../utils/productPerPage'

type Props = PageProps<
  LandingPageQueryQuery,
  LandingPageQueryQueryVariables,
  CmsInstitutionalPage & { node: Node }
> & {
  slug: string
  sort?: string
  pageContext: { cmsPageData: { node: Node } }
}

interface PageViewProps {
  setDidMount: React.Dispatch<React.SetStateAction<boolean>>
}

const getSortInUrl = (searchUrl: string) => {
  if (searchUrl?.length > 0) {
    const splitSearch = searchUrl?.split(/[&=]/)

    return splitSearch?.find(
      (item, index) =>
        item === 'sort' &&
        OptionsMap?.[splitSearch?.[index + 1] as keyof typeof OptionsMap]
    )
  }

  return false
}

const useSearchParams = (props: Props, facets?: Facet[]): SearchState => {
  const {
    location: { href, pathname, search },
  } = props

  const selectedFacets = facets

  const sort: string = props.pageContext?.cmsPageData?.node?.config
    ?.slugAndFilterConfig?.sort
    ? props.pageContext?.cmsPageData?.node?.config?.slugAndFilterConfig?.sort
    : 'score_desc'

  return useMemo(() => {
    const maybeState = href ? parseSearchState(new URL(href)) : null
    const searchSort = !getSortInUrl(search) ? sort : maybeState?.sort

    return {
      page: maybeState?.page ?? 0,
      base: maybeState?.base ?? pathname,
      selectedFacets:
        maybeState && maybeState.selectedFacets.length > 0
          ? maybeState.selectedFacets
          : selectedFacets ?? [],
      term: maybeState?.term ?? null,
      sort: (searchSort as SearchSort) ?? 'score_desc',
    }
  }, [href, pathname, selectedFacets, search, sort])
}

function convertFacets(facet: string | undefined | null, index: number) {
  let keyFacet = facet?.replace(' ', '-').toLocaleLowerCase() ?? ''

  if (facet === 'categoria') {
    keyFacet = `category-${index + 1}`
  }

  return keyFacet
}

function getKey(key: string | null | undefined, index: number) {
  return convertFacets(
    key
      ?.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase(),
    index
  )
}

function PageViewlp({ setDidMount }: PageViewProps) {
  useEffect(() => {
    setDidMount(true)
  }, [])
}

const getCmsData = async (
  setPreview: React.Dispatch<React.SetStateAction<PreviewData>>,
  SearchParams: URLSearchParams
) => {
  const contentType = SearchParams.get('contentType')
  const documentId = SearchParams.get('documentId')
  const versionId = SearchParams.get('versionId')

  if (contentType && documentId && versionId) {
    await axios
      .post('/api/getCmsContent', {
        contentType,
        documentId,
        versionId,
      })
      .then((response) => {
        setPreview(response?.data)
      })
      .catch((error) => {
        console.error('Error -> ', error)
      })
  }
}

function Page(props: Props) {
  const [didMount, setDidMount] = useState(false)
  const [sendEventPage, setSendEventPage] = useState(false)
  const [preview, setPreview] = useState<PreviewData>(null)
  const { person } = useSession()
  const { sendPageViewEvent } = usePageViewEvent('Landing Page', person)
  const { setCampaignPage } = useCampaignContext()
  const [requestData, setRequestData] = useState<
    AxiosRequestConfig | undefined
  >(undefined)

  setCampaignPage(false)

  useEffect(() => {
    const SearchParams = new URLSearchParams(window?.location?.search)

    if (SearchParams) {
      getCmsData(setPreview, SearchParams)
    }
  }, [])

  const timerRef = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {
    timerRef.current && clearTimeout(timerRef.current)

    timerRef.current = setTimeout(() => {
      if (!sendEventPage) {
        sendPageViewEvent()
        setSendEventPage(true)
      }
    }, 500)
  }, [person])

  useEffect(() => {
    const type =
      props?.path?.indexOf('institucional') > -1 ? 'Landing Page' : 'Category'

    if (didMount) {
      window.insider_object = window.insider_object || {}
      window.insider_object.page = {
        type,
        originalType: type,
      }
    }
  }, [didMount])

  PageViewlp({
    setDidMount,
  })

  const {
    data: { site },
  } = props

  const cmsPageData = preview ?? props.pageContext.cmsPageData.node

  const visibleFilters =
    cmsPageData?.config?.slugAndFilterConfig?.visibleFilters

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedFacetsFilter: Array<{ key: string; value: string }> = []
  const filterCMS =
    cmsPageData?.config?.slugAndFilterConfig?.filterGroup?.allItems

  useEffect(() => {
    const categoryId = '1'

    const getSepecification = async () => {
      try {
        const { data: dataSpecification } = await axios.post(
          '/api/productSpecification/getSpecificationsFields',
          { categoryId }
        )

        setRequestData(dataSpecification)
      } catch (error) {
        throw new Error(`Um erro ocorreu: ${error.message}`)
      }
    }

    getSepecification()
  }, [])

  const setFilters = () => {
    if (filterCMS?.length) {
      filterCMS.forEach((item, index) => {
        const facetKey = getKey(item?.key, index)

        const value = getFacetValue(item)

        const pushToSelectedFacetsFilter = (newKey: string) => {
          selectedFacetsFilter.push({
            key: newKey,
            value,
          })
        }

        const key = handleKeyTransform(
          facetKey,
          requestData as DataSpecification
        )

        if (requestData) {
          pushToSelectedFacetsFilter(key)
        }
      })
    }
  }

  setFilters()

  const searchParams = useSearchParams(props, selectedFacetsFilter)

  const { width } = useWindowDimensions()

  const ITEM_PER_PAGE_VERIFIED = productPerPage(width)

  return (
    didMount && (
      <SearchProvider
        onChange={applySearchState}
        itemsPerPage={ITEM_PER_PAGE_VERIFIED}
        {...searchParams}
      >
        <FilterContextProvider>
          {/* SEO */}
          <Seo
            data={props.data}
            location={props.location}
            cmsData={cmsPageData}
          />
          <Above cmsData={cmsPageData} />
          <Below
            cmsData={cmsPageData}
            selectedFacets={selectedFacetsFilter}
            searchParams={searchParams}
            site={site}
            visibleFilters={visibleFilters}
            sendInsiderEvent
          />
        </FilterContextProvider>
      </SearchProvider>
    )
  )
}

function getFacetValue(
  item: {
    key?: string | null
    value?: string | null
  } | null
): string {
  if (!item?.value) {
    return ''
  }

  if (item.key === 'grupo-etário') {
    return item.value
  }

  return item.value
    .replaceAll(' ', '-')
    .replaceAll('+', '-')
    .replaceAll('&', '-')
    .toLocaleLowerCase()
}

/**
 * This query will run during SSG
 * */
export const querySSG = graphql`
  query LandingPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    cmsHome {
      sections {
        name
        data
      }
    }
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`
type PreviewData = {
  name: string
  sections: Array<{
    data: CmsBlock['data']
    name: string
  }>
  seo: {
    siteMetadataWithSlug: {
      slug: string | null
      description: string | null
      title: string | null
      titleTemplate: string | null
    } | null
  } | null
  config: {
    slugAndFilterConfig: {
      slug: string
      sort: string
      visibleFilters:
        | { allItems: Array<{ filterKey: string | null } | null> | null }
        | null
        | undefined
      filterGroup: {
        allItems:
          | Array<{
              key: string | null
              value: string | null
            } | null>
          | null
          | undefined
      }
    }
  } | null
} | null

type Node = {
  name: string
  sections: Array<{ data: any; name: string }>
  seo: {
    siteMetadataWithSlug: {
      slug: string | null
      description: string | null
      title: string | null
      titleTemplate: string | null
    } | null
  } | null
  config: {
    slugAndFilterConfig: {
      slug: string | null
      background: string | null
      sort: string | null
      headerType: string | null
      filterGroup: {
        allItems: Array<{
          key: string | null
          value: string | null
        } | null> | null
      } | null
      visibleFilters: {
        allItems: Array<{ filterKey: string | null } | null> | null
      } | null
    } | null
  } | null
}

export default Page
