import type { CmsBlock } from '@generated/graphql'
import React from 'react'
import RenderCMS from 'src/components/RenderCMS'
import ProductGallery from 'src/components/sections/ProductGallery'

interface Props {
  cmsData: {
    sections: Array<{ name: string; data: CmsBlock['data'] }>
    config: {
      slugAndFilterConfig: {
        slug: string | null
        background?: string | null
      } | null
    } | null
  }
  selectedFacets: Array<{ key: string; value: string }>
  searchParams: {
    base: string
  }
  site: {
    siteMetadata: {
      title: string | null
    } | null
  } | null
  visibleFilters?: {
    allItems: Array<{
      filterKey: string | null
    } | null> | null
  } | null
  sendInsiderEvent?: boolean
}

const Below = (props: Props) => {
  const { cmsData, selectedFacets, searchParams, site, visibleFilters } = props

  const { base } = searchParams
  const title = site?.siteMetadata?.title ?? ''
  const background =
    cmsData?.config?.slugAndFilterConfig?.background ?? '#FFFFFF'

  const afterGallerySectionIndex = cmsData?.sections.findIndex(
    (sectionObject) => sectionObject.name === 'AfterGallery'
  )

  const beforeGallerySectionsComponents =
    afterGallerySectionIndex !== -1
      ? cmsData?.sections.slice(0, afterGallerySectionIndex)
      : cmsData?.sections

  const afterGallerySectionsComponents =
    afterGallerySectionIndex !== -1
      ? cmsData?.sections.slice(
          afterGallerySectionIndex + 1,
          cmsData.sections.length
        )
      : null

  return (
    <>
      <section
        className="page__section cms-departament-components"
        style={{ background }}
      >
        <RenderCMS sections={beforeGallerySectionsComponents} fold="below" />
      </section>

      {selectedFacets.length > 0 ? (
        <>
          <ProductGallery
            term={null}
            base={base}
            title={title}
            description={title ?? ''}
            visibleFilters={visibleFilters}
            defaultFacets={selectedFacets}
            sendInsiderEvent
          />
        </>
      ) : null}

      {afterGallerySectionsComponents && (
        <section
          className="page__section cms-departament-components after-gallery"
          style={{ background }}
        >
          <RenderCMS
            sections={afterGallerySectionsComponents}
            fold="below"
            isAfterGallery
          />
        </section>
      )}
    </>
  )
}

export default Below
