import { ITEMS_PER_PAGE, ITEMS_PER_PAGE_TABLET } from 'src/constants'

export default function ProductPerPage(width: number) {
  if (width < 768) {
    return ITEMS_PER_PAGE
  }

  if (width < 853) {
    return ITEMS_PER_PAGE_TABLET
  }

  return ITEMS_PER_PAGE
}
