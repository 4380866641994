import React, { useMemo } from 'react'
import type { FC } from 'react'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { useSession } from 'src/sdk/session'
import type { CmsInstitutionalPageSeo } from '@generated/graphql'
import { generateBreadcrumb } from 'src/utils/generateBreadcrumb'

import storeConfig from '../../../store.config'

interface CmsDataSeo {
  seo: CmsInstitutionalPageSeo | null
}

interface SeoProps {
  data: {
    site: SiteProps | null | undefined
  }
  location: { pathname: string; host: string; origin: string }
  cmsData: CmsDataSeo
}

type SiteProps = {
  siteMetadata: SiteMetaData | null | undefined
}

type SiteMetaData = {
  title: string | null | undefined
  description: string | null | undefined
  titleTemplate: string | null | undefined
}

const Seo: FC<SeoProps> = (props) => {
  const {
    data: { site },
    location: { pathname, origin },
    cmsData,
  } = props

  const filterTitle = cmsData?.seo?.siteMetadataWithSlug?.title
  const filterDescription = cmsData?.seo?.siteMetadataWithSlug?.description

  const { locale } = useSession()
  const title = site?.siteMetadata?.title ?? ''

  const { storeUrl } = storeConfig
  const canonical = `${storeUrl}${pathname}`

  const breadcrumbList = useMemo(
    () => generateBreadcrumb(pathname, origin),
    [origin, pathname]
  )

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={filterTitle ?? ''}
        description={filterDescription ?? ''}
        canonical={canonical}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <BreadcrumbJsonLd itemListElements={breadcrumbList} />
    </>
  )
}

export default Seo
